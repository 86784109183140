<template>
  <div class="fillOrder">
    <div class="fillOrder-title"><b>填写订单信息</b></div>
    <div class="mainbody">
      <a-card>
        <a-row type="flex" align="middle" class="lh60">
          <a-col :span="24"> 
            <a-space :size="24">
              <span> 下游管理 </span>
              <a-select v-model="confirm.downstreamId" style="width: 320px" :dropdownMatchSelectWidth="false">
                <a-select-option :key="null">
                  无特定下游
                </a-select-option>
                <a-select-option v-for="item in dsList" :key="item.id">
                  {{item.downstreamName}}
                </a-select-option>
              </a-select>
            </a-space>
          </a-col>
          <a-col :span="24"> 收货地址 </a-col>
        </a-row>
        <a-row
          type="flex"
          class="bd1"
          align="middle"
          :class="!updateinfo.detailAddr && !updateinfo.tagAddr && bord ? 'warn' : ''"
        >
          <div class="address">
            <a-col :span="3" class="color999">地址标签：</a-col>
            <a-col :span="21">{{ updateinfo.tagAddr }}&nbsp;</a-col>
            <a-col :span="3" class="color999">收货地址：</a-col
            ><a-col :span="21">{{ updateinfo.fullAddr }}</a-col>
          </div>
          <div class="addr" @click="showModal">
            <span class="addr-text">修改地址</span>
          </div>
          <div class="line"></div>
          <div class="switch-address">
            <a-button type="primary" class="w140" @click="switchfill = true">
              <a-icon type="environment" />切换地址 </a-button
            >&nbsp;&nbsp;
            <a-button type="primary" class="w140" @click="addfill = true">
              <a-icon type="plus-circle" />添加地址</a-button
            >
          </div>
        </a-row>
      </a-card>
      <p
        class="red pt5"
        v-if="!updateinfo.detailAddr && !updateinfo.tagAddr && bord"
      >
        请填写收货地址
      </p>
      <a-row type="flex"  align="middle" class="lh60" v-if="!supplierType">
        <a-col :span="24"> 发票信息 </a-col>
      </a-row>
      <a-row class="bd1" :class="verify2()!==true && bord2?'warn':''" style="padding: 10px 30px" v-if="!supplierType">
        <div class="invoice-info lh22">
          <div class="left">
            <div>
              <p>
                <span class="color999">发票抬头：</span>
                <span>{{ indexInfo.purchaserInvoiceInfo.commercialName }}</span>
              </p>
              <p>
                <span class="color999">发票银行账号：</span>
                <span>
                  {{ indexInfo.purchaserInvoiceInfo.invoiceBankAcc }}</span
                >
              </p>
              <p>
                <span class="color999"> 发票银行名称：</span
                ><span>{{
                  indexInfo.purchaserInvoiceInfo.invoiceBankName
                }}</span>
              </p>
              <p>
                <span class="color999">电子发票邮箱：</span>
                <span>{{ indexInfo.purchaserInvoiceInfo.invoiceEmail }}</span>
              </p>
            </div>
            <div>
              <p>
                <span class="color999">纳税人识别号：</span>
                <span>{{ indexInfo.purchaserInvoiceInfo.uniformCode }} </span>
              </p>
              <p>
                <span class="color999">发票电话：</span
                ><span>{{ indexInfo.purchaserInvoiceInfo.invoiceMobile }}</span>
              </p>
              <div class="invaddr">
                <span class="color999 invspan">发票地址：</span>
                <p>{{ indexInfo.purchaserInvoiceInfo.invoiceAddr }}</p>
              </div>
            </div>
          </div>
          <div class="right">
            <a-button @click="amendinfo = true" type="primary"
              ><a-icon type="form" />修改信息</a-button
            >
          </div>
        </div>
      </a-row>
      <p class="red pt5" v-if="verify2()!==true && bord2 && !supplierType">{{this.invoiceText}}</p>
      <a-row class="inv-text">
        <a-col :span="7">
          开票品类名称：<span class="fw">{{
            indexInfo.invoiceName
          }}</span></a-col
        >
        <a-col :span="7">
          发票编码：<span class="fw">{{ indexInfo.invoiceCode }}</span>
        </a-col>
        <a-col :span="4">
          规格： <span class="fw">{{ indexInfo.specName }}</span></a-col
        >
      </a-row>
      <a-row type="flex" align="middle" class="lh60">
        <a-col :span="24"> 商品信息 </a-col>
      </a-row>
      <a-row class="goods-info">
        <div>
          <a-table :columns="columns" :pagination="false"></a-table>
        </div>
        <a-row class="pt20">
          <a-col :span="7" align="right"
            ><span>{{ indexInfo.invoiceName }}</span>
            <span v-if="indexInfo.specName"
              >-{{ indexInfo.specName }}
            </span></a-col
          >
          <a-col :span="5" align="right" >{{typeof indexInfo.unitPrice == 'number' && indexInfo.unitPrice.toFixed(2)  }}</a-col>
          <a-col :span="5" align="right"
            ><span>{{ indexInfo.quantity }}</span
            ><span>{{ indexInfo.unitsMeasure | unit }}</span></a-col
          >
          <a-col :span="7" align="right" style="color: red"
            >￥{{ typeof indexInfo.taxableAmount == 'number' &&indexInfo.taxableAmount.toFixed(2) }}</a-col
          >
        </a-row>
      </a-row>
      <a-row
        style="
          padding: 40px 0 0 0;
          font-size: 16px;
          color: #666;
          font-weight: 400;
        "
      >
        <a-col :span="24"> 金额明细 </a-col>
      </a-row>
      <a-divider type="horizontal" />
      <a-row type="flex" justify="end" class="sum-amount lh22">
        <a-col :span="22" align="right">商品总价：</a-col>
        <a-col :span="2" align="right" class="red pr" >{{
          typeof indexInfo.taxableAmount == 'number' && indexInfo.taxableAmount.toFixed(2)
        }}</a-col>
        <a-col :span="22" align="right">{{this.indexInfo.vatRate | unitText}}</a-col>
        <a-col :span="2" align="right" class="red">{{
          typeof indexInfo.vatTax == 'number' && indexInfo.vatTax.toFixed(2)
        }}</a-col>
        <a-col :span="22" align="right" class="lh40">应付总额：</a-col>
        <a-col :span="2" align="right" class="red lh40" >{{
          typeof indexInfo.amountWithTax == 'number' && indexInfo.amountWithTax.toFixed(2)
        }}</a-col>
      </a-row>
    </div>
    <a-row type="flex" align="middle" class="create-order">
      <a-col :span="7">
        卖方：<span class="fw">{{ indexInfo.supplierName }}</span>
      </a-col>
      <a-col :span="13">
        数量：<span class="fw">{{ indexInfo.quantity }}</span
        ><span class="fw">{{ indexInfo.unitsMeasure | unit }}</span>
      </a-col>
      <a-col :span="7"
        >商品金额（不含增值税）：<span class="fw" >{{
          typeof indexInfo.taxableAmount == 'number' && indexInfo.taxableAmount.toFixed(2)
        }}</span>
        <span class="fw">元</span>
      </a-col>
      <a-col :span="13">
        增值税：<span class="fw" >{{ typeof indexInfo.vatTax == 'number' && indexInfo.vatTax.toFixed(2) }}</span>
        <span class="fw">元</span>
      </a-col>
      <a-col :span="4"
        ><a-button
          type="primary"
          class="btn-order"
          :loading="loading"
          @click="produceOrder"
          >生成订单</a-button
        ></a-col
      >
    </a-row>
    <!-- 修改地址 -->
    <a-modal
      :visible="amendfill"
      cancelText="取消"
      okText="保存"
      title="修改收货地址"
      @ok="amendAddress"
      :maskClosable="false"
      @cancel="handleCancel1"
    >
      <a-row class="pt10">
        <a-col>地址标签</a-col>
        <a-col class="pt5">
          <a-input
            v-model="updateinfo.tagAddr"
            placeholder="给该地址一个别名，便于识别。例如：青岛工厂仓库"
        /></a-col>
      </a-row>
      <a-row class="pt20">
        <a-col>省份/自治区</a-col>
        <a-col></a-col>
        <a-col class="pt5">
          <a-cascader
            style="width: 100%"
            :field-names="{
              value: 'key',
              label: 'label',
              children: 'children'
            }"
            :options="area"
            :key="updateinfo.provinceCode"
            :default-value="[
              updateinfo.provinceCode,
              updateinfo.cityCode,
              updateinfo.areaCode
            ]"
            placeholder="选择省/市/区"
            @change="ChangeAmend"
          />
        </a-col>
      </a-row>
      <a-row class="pt20">
        <a-col>详细地址</a-col>
        <a-col class="pt5">
          <a-textarea
            v-model="updateinfo.detailAddr"
            :auto-size="{ minRows: 5, maxRows: 5 }"
          />
        </a-col>
      </a-row>
      <a-col class="pt20">
          <a-checkbox :checked="checked" @change="checked = $event.target.checked">
            默认地址
          </a-checkbox>
        </a-col>
    </a-modal>
    <!-- 切换地址 -->
    <a-modal
      :width="600"
      :visible="switchfill"
      title="切换收货地址"
      cancelText="取消"
      okText="保存"
      @ok="save"
      :maskClosable="false"
      @cancel="
        switchfill = false;
        save()
      "
    >
      <div class="scrollY">
        <div
          class="ress"
          v-for="item in listAddress"
          :key="item.id"
          :class="item.id === pitchs ? 'bordred' : ''"
          @click="pitch(item)"
        >
          <a-row>
            <a-col :span="4" class="color999">地址标签：</a-col>
            <a-col :span="12">{{ item.tagAddr }}</a-col>
          </a-row>
          <a-row>
            <a-col :span="4" class="color999">收货地址：</a-col>
            <a-col :span="15">{{ item.fullAddr }}</a-col>
            <a-col :span="2" class="cursor">
              <a-popconfirm title="确认删除?" @confirm="() => del(item.id)">
                删除
              </a-popconfirm>
            </a-col>

            <a-col :span="3" aligh="right" v-if="item.isDefault === 1"
              >默认地址</a-col
            >
            <a-col
              :span="3"
              aligh="right"
              @click="switchAddress(item.id)"
              v-if="item.isDefault === 0"
              class="cursor"
              style="color: #1890ff"
              >设为默认</a-col
            >
          </a-row>
        </div>
      </div>
    </a-modal>
    <!-- 添加地址 -->
    <a-modal
      :visible="addfill"
      cancelText="取消"
      okText="保存"
      title="添加收货地址"
      :maskClosable="false"
      @ok="addAddress"
      @cancel="addfill = false;checkedAdd = false"
    >
      <a-row class="pt10">
        <a-col>地址标签</a-col>
        <a-col class="pt5">
          <a-input
            v-model="addinfo.tagAddr"
            placeholder="给该地址一个别名，便于识别。例如：青岛工厂仓库"
        /></a-col>
      </a-row>
      <a-row class="pt20">
        <a-col>省份/自治区</a-col>
        <a-col class="pt5">
          <a-cascader
            style="width: 100%"
            :field-names="{
              value: 'key',
              label: 'label',
              children: 'children'
            }"
            ref="Cascader"
            :options="area"
            :default-value="[
              addinfo.provinceCode,
              addinfo.cityCode,
              addinfo.areaCode
            ]"
            placeholder="选择省/市/区"
            @change="onChange"
          />
        </a-col>
      </a-row>
      <a-row class="pt20">
        <a-col>详细地址</a-col>
        <a-col class="pt5">
          <a-textarea
            v-model="addinfo.detailAddr"
            :auto-size="{ minRows: 5, maxRows: 5 }"
          />
        </a-col>
        <a-col class="pt20">
          <a-checkbox :checked="checkedAdd" @change="checkedAdd = $event.target.checked">
            默认地址
          </a-checkbox>
        </a-col>
      </a-row>
    </a-modal>
    <!-- 发票信息 -->
    <a-modal
      :centered="true"
      :width="600"
      :visible="amendinfo"
      cancelText="取消"
      okText="保存"
      :maskClosable="false"
      @ok="invoiceOk"
      @cancel="amend"
    >
      <a-row>
        <a-col align="center" class="fs16">发票信息</a-col>
      </a-row>
      <a-row>
        <a-col>发票抬头</a-col>
        <a-col class="pt5"
          ><a-input
            disabled
            v-model="purchaserInvoiceInfo.commercialName"
          ></a-input
        ></a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>纳税人识别号</a-col>
        <a-col class="pt5"
          ><a-input
            disabled
            v-model="purchaserInvoiceInfo.uniformCode"
          ></a-input
        ></a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票地址</a-col>
        <a-col class="pt5"
          ><a-input
          @input="purchaserInvoiceInfo.invoiceAddr=$event.target.value.replace(/^\s+|\s+$/g,'')"
            v-model="purchaserInvoiceInfo.invoiceAddr"
          ></a-input
        ></a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票电话</a-col>
        <a-col class="pt5"
          ><a-input
          :maxLength="18"
            v-model="purchaserInvoiceInfo.invoiceMobile"
            @input="purchaserInvoiceInfo.invoiceMobile=$event.target.value.replace(/[^\d|-]/g,'')"
          ></a-input
        ></a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票银行名称</a-col>
        <a-col class="pt5"
          ><a-input
            @input="purchaserInvoiceInfo.invoiceBankName=$event.target.value.replace(/^\s+|\s+$/g,'')"
            v-model="purchaserInvoiceInfo.invoiceBankName"
          ></a-input
        ></a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票银行账号</a-col>
        <a-col class="pt5"
          ><a-input
            :maxLength="30"
            @input="purchaserInvoiceInfo.invoiceBankAcc=$event.target.value.replace(/^\s+|\s+$/g,'')"
            v-model="purchaserInvoiceInfo.invoiceBankAcc"
          ></a-input
        ></a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>电子发票邮箱</a-col>
        <a-col class="pt5"
          ><a-input
            v-model="purchaserInvoiceInfo.invoiceEmail"
          ></a-input
        ></a-col>
      </a-row>
      <a-row class="pt10">
        <a-col>发票须知：</a-col>
        <a-col>1. 订单付款后纳税人识别号不可修改，请确认信息后付款；</a-col>
        <a-col
          >2.
          在订单完成后申请开票，纸制发票我方会统一开票后寄给买方，电子发票会通过邮件发送。</a-col
        >
        <a-col>3. 发票金额为实际支付金额。</a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
const columns = [
  {
    title: '商品信息',
    dataIndex: 'name',
    width: '25%',
    align: 'right',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '单价',
    width: '24%',
    align: 'right',
    dataIndex: 'money'
  },
  {
    title: '数量',
    width: '21%',
    align: 'right',
    dataIndex: 'age'
  },
  {
    title: '商品总价',
    align: 'right',
    width: '27%',
    color: 'red',
    dataIndex: 'pay',
    scopedSlots: { customRender: 'pay' }
  }
]
const regEmail = /.{1,}@.{1,}\..{1,}/

import {
  confirm,
  list,
  getdefault,
  setdefault,
  createAddress,
  updateAddress,
  deleteAddress,
  createOrder
} from '../../api/order'
import { updateinvoice } from '@/api/seller'
import { dsPur } from '@/api/buyerCenter'
import area from '../../utils/ara'
import { type } from '../../utils/constArr'
export default {
  data() {
    return {
      area,
      columns,
      supplierType: Number(this.$route.query.supplierType),
      confirm: {
        merchandiseId: Number(this.$route.query.merchandiseId),
        purchaserId: Number(this.$route.query.purchaserId),
        taxRegionPolicyId: Number(this.$route.query.taxRegionPolicyId),
        quantity: Number(this.$route.query.quantity),
        grossAmount: Number(this.$route.query.grossAmount),
        downstreamId: null
      },
      checked: false,
      checkedAdd: false,
      updateinfo: {
        //修改地址
        addrId: null, //收获ID
        provinceCode: '', //省份编码
        areaCode: '', //区域编码
        cityCode: '', //城市编码
        detailAddr: '', //详情地址
        tagAddr: '' //地址标签
      },
      listAddress: [],
      addinfo: {
        //添加地址
        purchaserId: this.$route.query.purchaserId, //收获ID
        provinceCode: '', //省份编码
        areaCode: '', //区域编码
        cityCode: '', //城市编码
        detailAddr: '', //详情地址
        tagAddr: '' //地址标签
      },
      indexInfo: {
        //页面数据
        merchandiseId: null, //商品id
        supplierName: '', //卖家名称
        invoiceName: '', //开票名称
        invoiceCode: '', //开票编码
        specName: '', //规格型号
        unitPrice: null, //单价
        quantity: '', //采购数量
        unitsMeasure: null, //单位
        taxableAmount: null, //应税总价--不含增值税的总价
        amountWithTax: null, //含税总价
        vatTax: null, //增值税金额
        vatRate: null, //增值税税率
        addressDtoList: [],
        purchaserInvoiceInfo: {}
      },
      pitchs: undefined,
      purchaserInvoiceInfo: {
        id: this.$route.query.purchaserId, //买家id
        commercialName:'', //主体名称，发票抬头
        uniformCode:'', //证件号：企业统一信用代码
        invoiceAddr:'', //发票地址
        invoiceMobile:'', //发票电话
        invoiceBankName:'', //发票银行开户行
        invoiceBankAcc:'', //发票银行账号
        invoiceEmail:'' //电子发票投递邮箱
      },
      amendfill: false, //修改
      switchfill: false, //切换
      addfill: false, //添加
      amendinfo: false, //修改信息,
      bord: false, //警告框
      bord2:false, //警告框
      loading: false,
      invoiceText:'',
      dsList: [] // 下游管理列表
    }
  },
  created() {
    this.getconfirm()
    this.getdefaults()
    dsPur(this.$route.query.purchaserId).then(res => {
      if (res.code === 200) {
        this.dsList = res.data
      }
    })
  },
  filters: {
    unit: (val) => type[val],
    unitText(val) {
      if (val === 0) {
        return '增值税 0%：'
      }
      if (val) {
        return `增值税 ${val * 100}%：`
      }
      return ''
    }
  },
  methods: {
    showModal() {
      if (this.updateinfo.tagAddr === '') {
        this.$message.error('请先添加地址')
      } else this.amendfill = true
    },
    handleCancel1() {
      this.getList()
    },
    amend() {
      this.amendinfo = false
      this.getconfirm()
    },
    //修改信息按钮
    // 修改发票信息
    invoiceOk() {
      if (!this.verify(true)) return
      updateinvoice({
        entityCharacter: 'P',
        ...this.purchaserInvoiceInfo,
      }).then((res) => {
        if (res.code === 200) {
          this.amendinfo = false
          this.getconfirm()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //获取页面数据
    getconfirm() {
      confirm(this.confirm).then((res) => {  
        if (res.code === 200) {
        this.indexInfo = res.data
        this.purchaserInvoiceInfo={...res.data.purchaserInvoiceInfo}
        this.bord2=true
        }else{
          this.$message.error(res.message)
        }
      })
      // 获取地址列表
      list({
        purchaserId: this.$route.query.purchaserId
      }).then((res) => {
        this.listAddress = res.data
        if (res.code === 200) {
          this.bord = true
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取地址列表
    getList() {
      list({
        purchaserId: this.$route.query.purchaserId
      }).then((res) => {
        this.listAddress = res.data
        if (res.code === 200) {
          const showAddress = this.listAddress.find((item) => {
            return item.id === this.pitchs
          })
          this.updateinfo = { ...this.updateinfo, ...showAddress }
          this.checked = !!this.updateinfo.isDefault
          this.amendfill = false
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取默认地址
    getdefaults() {
      getdefault({
        purchaserId: this.$route.query.purchaserId
      }).then((res) => {
        if (!res.code === 200) {
          this.$message.error(res.message)
        }
        // else {
        //   res.data
        //     ? (this.updateinfo.addrId = res.data.id)
        //     : (this.updateinfo.addrId = undefined)
        // }
        if (!res.data) {
          this.updateinfo = {
            addrId: undefined,
            provinceCode: '',
            areaCode: '',
            cityCode: '',
            detailAddr: '',
            tagAddr: ''
          }
        } else {
          !this.pitchs &&
            (this.updateinfo = { ...this.updateinfo, ...res.data }) &&
            (this.pitchs = res.data.id)
            this.checked = !!this.updateinfo.isDefault
        }
      })
    },
    //删除地址
    del(id) {
      deleteAddress({ addrId: id }).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.getconfirm()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 修改地址
    amendAddress() {
      if (!this.updateinfo.tagAddr) {
        this.$message.error('请填写一个地址标签')
      } else if (!this.updateinfo.provinceCode) {
        this.$message.error('请选择一个省市区')
      } else if (!this.updateinfo.detailAddr) {
        this.$message.error('请填写一个详细地址')
      } else
        return updateAddress({
          ...this.updateinfo,
          addrId: this.updateinfo.id,
          isDefault: this.checked ? 1 : 0
        }).then((res) => {
          if (res.code === 200) {
            this.getList()
            this.checked = false
          } else {
            this.$message.error(res.message)
          }
        })
    },
    pitch(e) {
      this.pitchs = e.id
    },
    // 切换地址
    save() {
      if (this.listAddress == '') {
        this.updateinfo = {
          addrId: undefined,
          provinceCode: '',
          areaCode: '',
          cityCode: '',
          detailAddr: '',
          tagAddr: ''
        }
        return
      }
      const showAddress = this.listAddress.find((item) => {
        return item.id === this.pitchs
      })
      showAddress.addrId = this.pitchs
      this.updateinfo = { ...this.updateinfo, ...showAddress }
      this.checked = !!this.updateinfo.isDefault
      this.switchfill = false
      this.getconfirm()
    },
    // 设置默认地址
    switchAddress(id) {
      setdefault({ addrId: id }).then((res) => {
        if (res.code === 200) {
          this.getconfirm()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 添加地址
    addAddress() {
      if (!this.addinfo.tagAddr) {
        this.$message.error('请填写一个地址标签')
      } else if (!this.addinfo.provinceCode) {
        this.$message.error('请选择一个省市区')
      } else if (!this.addinfo.detailAddr) {
        this.$message.error('请填写一个详细地址')
      } else
        return createAddress({ ...this.addinfo, isDefault: this.checkedAdd ? 1 : 0  }).then((res) => {
          if (res.code === 200) {
            this.checked = false
            this.getList()
            this.getdefaults()
            this.addinfo = {
              purchaserId: this.$route.query.purchaserId,
              provinceCode: '', //省份编码
              areaCode: '', //区域编码
              cityCode: '', //城市编码
              detailAddr: '', //详情地址
              tagAddr: '' //地址标签
            }
            this.$refs.Cascader.sValue = []
            this.addfill = false
            this.checkedAdd = false
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    ChangeAmend(value) {
      this.updateinfo.provinceCode = value[0]
      this.updateinfo.cityCode = value[1]
      this.updateinfo.areaCode = value[2]
    },
    onChange(value) {
      this.addinfo.provinceCode = value[0]
      this.addinfo.cityCode = value[1]
      this.addinfo.areaCode = value[2]
    },
    // 生成订单
    produceOrder() {
      if (!this.verify()) return
      this.loading = true
      createOrder({
        ...this.confirm,
        deliveryAddressId: this.pitchs
      })
        .then((res) => {
          this.loading = false
          if (res.code === 200) {
            this.$router.replace({
              path: '/succee-order',
              query: {
                id: res.data,
                purchaserId: this.$route.query.purchaserId
              }
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((r) => {
          this.$message.error(r.message)
        })
    },
    verify(b) {
      if (!this.updateinfo.tagAddr && !b) {
        this.$message.error('请先添加收货地址')
      } else if (!this.updateinfo.fullAddr && !b) {
        this.$message.error('请先填写收货地址')
      }else if (!this.purchaserInvoiceInfo.invoiceAddr && !this.supplierType) {
        this.$message.error('请填写发票地址')
      } else if (!this.purchaserInvoiceInfo.invoiceMobile && !this.supplierType) {
        this.$message.error('请填写正确的发票电话')
      } else if (!this.purchaserInvoiceInfo.invoiceBankName && !this.supplierType) {
        this.$message.error('请填写发票银行名称')
      } else if (!this.purchaserInvoiceInfo.invoiceBankAcc && !this.supplierType) {
        this.$message.error('请填写发票银行账户')
      } else if (!regEmail.test(this.purchaserInvoiceInfo.invoiceEmail) && !this.supplierType) {
        this.$message.error('请填写正确的电子发票邮箱')
      } else return true
    },
      verify2(){
      if (!this.indexInfo.purchaserInvoiceInfo.invoiceBankAcc) {
        this.invoiceText='请补充完整的信息'
      }else if (!this.indexInfo.purchaserInvoiceInfo.invoiceMobile) {
        this.invoiceText='请补充完整的信息'
      }else if (!this.indexInfo.purchaserInvoiceInfo.invoiceBankName) {
        this.invoiceText='请补充完整的信息'
      }else if (!this.indexInfo.purchaserInvoiceInfo.invoiceEmail) {
        this.invoiceText='请补充完整的信息'
      }else if (!this.indexInfo.purchaserInvoiceInfo.invoiceAddr) {
        this.invoiceText='请补充完整的信息'
      }  
      else return true 
    }
  }
}
</script>
<style lang="less" scoped>
.fillOrder {
  max-width: 1440px;
  min-width: 990px;
  @media screen and (max-width: 1440px){
    padding: 0 15px;
  }
  margin: auto;
  .fillOrder-title {
    line-height: 80px;
    font-size: 16px;
    font-weight: 400;
  }
  .mainbody {
    background-color: #ffffff;
    padding: 10px 50px;
    font-size: 14px;
    /deep/ .ant-card-bordered {
      border: 1px solid transparent;
    }
    /deep/.ant-card-body {
      padding: 0;
    }
    .lh60 {
      font-size: 16px;
      font-weight: 400;
      color: #666;
      line-height: 60px;
    }
    .bd1 {
      padding: 30px;
      border: 1px solid #ccc;
    }
    .address {
      width: 560px;
      line-height: 32px;
    }
    .addr {
      .addr-text {
        cursor: pointer;
        color: @primary;
      }
    }
    .line {
      padding-left: 60px;
      height: 80px;
      border-right: 1px solid #ccc;
    }
    .switch-address {
      padding-left: 48px;
    }
    .w140 {
      width: 150px;
      height: 40px;
      color: #999;
      background: rgb(245, 245, 245);
      border: none;
      &:hover {
        color: rgb(51, 51, 51);
      }
    }
    .lh22 {
      line-height: 32px;
    }
    .lh40 {
      line-height: 40px;
      font-size: 16px;
    }
    .amend-info {
      width: 60px;
      padding: 0 0 0 110px;
      color: royalblue;
      cursor: pointer;
    }
    .inv-text {
      padding: 30px 0 50px 30px;
    }
    .goods-info {
      padding-bottom: 20px;
      border: 1px solid rgb(232, 232, 232);
    }
    .red {
      color: red;
    }
  }
  .invoice-info {
    // height: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      width: 900px;
      display: flex;
      justify-content: space-between;
      p {
        width: 350px;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .create-order {
    background-color: rgba(249, 249, 249, 1);
    padding: 10px 30px;
    border: 1px solid rgba(228, 228, 228, 1);
    line-height: 28px;
    margin-bottom: 50px;
    font-size: 14px;
    .btn-order {
      width: 150px;
      height: 45px;
      background-color: red;
      border: none;
    }
  }
}
/deep/.ant-col-7 {
  padding-right: 10px;
}
.invaddr {
  p {
    margin-left: 70px;
  }
  .invspan {
    float: left;
  }
}
.ress {
  width: 530px;
  height: 110px;
  border: 1px solid #ccc;
  padding: 30px;
  margin-top: 20px;
  transition: all 0.2s;
}
.fw {
  font-weight: 600;
}
.scrollY {
  height: 510px;
  overflow-y: auto;
}
.color999 {
  color: #999999;
}
.fs16 {
  font-size: 16px;
}
.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt20 {
  padding-top: 20px;
}
.cursor {
  cursor: pointer;
}
.warn {
  border: 1px solid red !important;
}
/deep/.ant-modal-title {
  text-align: center;
}
/deep/ .ant-table-placeholder {
  display: none;
}
.bordred {
  border: 1px solid red;
}
</style>